import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

import { validateEmail, validatePhone } from 'utils/strings';

import AutocompleteField from 'components/form/AutocompleteField';

import { useWebApp } from 'web/contexts/WebContexts';
import { useUser } from 'web/contexts/UserContext';
import { FIND_TENANT_USERS } from 'api/queries/userQueries';

const ModalWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	z-index: 1300;
`;
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: #000000;
	opacity: 0.48;
`;
const Modal = styled.div`
	margin: 0 auto;
	width: 320px;
	background: white;
	position: relative;
	z-index: 1;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const ModalTitle = styled.h3`
	margin: 0 0 20px 0;
`;

const FieldWrapper = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;

export default function SigneeModal({ signee, handleSaveSignee, handleClose }) {
	return (
		<ModalWrapper>
			<Overlay onClick={handleClose} />

			<Modal>
				<SigneeModalForm
					signee={signee}
					handleSaveSignee={handleSaveSignee}
					handleClose={handleClose}
				/>
			</Modal>
		</ModalWrapper>
	);
}

const SigneeModalFormWrapper = styled.div`
	width: 100%;
`;

const SigneeModalFormActions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	margin-top: 20px;
`;

function SigneeModalForm({ signee, handleSaveSignee, handleClose }) {
	const { data: { tenant } = { tenant: {} } } = useWebApp();
	const { user } = useUser();

	const [state, setState] = useState({
		phone: signee.phone,
		email: signee.email,
		userId: signee.userId,
	});
	const [isUser, setIsUser] = useState(!!signee.userId);

	const [findTenantUsers, { data: { users } = { users: [] }, loading }] =
		useLazyQuery(FIND_TENANT_USERS, { fetchPolicy: 'network-only' });

	useEffect(() => {
		if (!tenant) return;

		findTenantUsers({
			variables: {
				tenantId: tenant._id,
			},
		});
	}, [tenant, isUser, user]);

	function isValid() {
		if (isUser) {
			return !!state.userId;
		}

		if (state.phone && state.email) {
			return validatePhone(state.phone) && validateEmail(state.email);
		}

		if (state.phone) {
			return validatePhone(state.phone);
		}

		if (state.email) {
			return validateEmail(state.email);
		}
	}

	if (loading) return null;

	return (
		<SigneeModalFormWrapper>
			<FieldWrapper>
				<ModalTitle>Rediger signering</ModalTitle>
			</FieldWrapper>

			<FormControlLabel
				control={
					<Switch
						checked={isUser}
						onChange={() => {
							setState({
								phone: '',
								email: '',
								userId: '',
							});

							setIsUser(!isUser);
						}}
					/>
				}
				label="Velg bruker"
				labelPlacement="start"
				sx={{ width: '100%' }}
			/>

			{!isUser && (
				<>
					<FieldWrapper>
						<TextField
							type="email"
							fullWidth
							label="E-postadresse"
							error={!validateEmail(state.email)}
							value={state.email}
							onChange={e =>
								setState({
									...state,
									email: e.target.value,
								})
							}
						/>
					</FieldWrapper>

					<FieldWrapper>
						<TextField
							type="tel"
							fullWidth
							label="Telefonnummer"
							value={state.phone}
							error={!validatePhone(state.phone)}
							onChange={e =>
								setState({
									...state,
									phone: e.target.value,
								})
							}
						/>
					</FieldWrapper>
				</>
			)}

			{isUser && !loading && (
				<FieldWrapper>
					<AutocompleteField
						label="Bruker"
						value={state.userId}
						fullWidth
						disablePortal
						options={
							users?.items?.map(user => {
								return {
									label: user.name,
									value: user._id,
									tooltip: [
										<Stack
											key="1"
											direction="row"
											alignItems="center"
											gap={1}
										>
											<MailIcon />
											{user.email || 'Mangler e-post'}
										</Stack>,
										<Stack
											key="2"
											direction="row"
											alignItems="center"
											gap={1}
										>
											<PhoneIcon />
											{user.phone || 'Mangler telefonnr'}
										</Stack>,
									],
								};
							}) || []
						}
						update={user => {
							setState({
								email: '',
								phone: '',
								userId: user.value,
							});
						}}
					/>
				</FieldWrapper>
			)}

			<SigneeModalFormActions>
				<Button
					variant="contained"
					size="large"
					disabled={!isValid()}
					onClick={() => {
						handleSaveSignee(state);
						handleClose();
					}}
					startIcon={<SendIcon />}
				>
					Oppdater
				</Button>
			</SigneeModalFormActions>
		</SigneeModalFormWrapper>
	);
}
